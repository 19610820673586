


































































































































































import { latLngToMgrs } from '@/utils/utils.js'
//@ts-ignore
import { EditableMap } from 'vue2-leaflet-editable'
import {
  LControlScale,
  LControl,
  LMarker,
  LLayerGroup,
  LTooltip,
  LPopup
} from 'vue2-leaflet'
//@ts-ignore
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure'
import debounce from 'lodash/debounce'
import L from 'leaflet'

import { mapState, mapActions, mapGetters } from 'vuex'
import distance from '@turf/distance'
import { PropType } from 'vue'

import {
  IPolylineOptions,
  IData,
  IFlyToMarker
} from '@/store/modules/maps/types'

import { ISentry } from '@/store/modules/sentries/types'
import { PluginOptions } from 'leaflet-simple-map-screenshoter'
import { IMapLayer } from '../Widgets/MapsAndZones/types'
import SimpleLTileLayer from '@/components/Map/SimpleLTileLayer.vue'
import BrowserAlert from '@/components/Base/BrowserAlert.vue'
import LeafletScreenshotTool from '@/components/Map/LeafletScreenshotTool.vue'
import { ESiteMode } from '@/store/modules/sites/types'

const props = {
  editable: {
    type: Boolean,
    default: false
  },
  zoomControl: {
    type: Boolean,
    default: true
  },
  searchBar: {
    type: Boolean,
    default: false
  },
  layerSwitch: {
    type: Boolean,
    default: true
  },
  name: {
    type: String,
    default: ''
  },
  zoom: {
    type: Number,
    default: 16
  },
  center: {
    type: Array,
    default: (): Array<Number> => [0, 0]
  },
  anchorable: {
    type: Boolean,
    default: false
  },
  measureEnabled: {
    type: Boolean,
    default: true
  },
  polylineOptions: {
    type: Object as PropType<IPolylineOptions>,
    default: () => ({
      position: 'topleft',
      tooltipTextDelete: 'Press SHIFT and click to <b>delete point</b>',
      measureControlTitleOn: 'Show measuring tool',
      measureControlTitleOff: 'Hide measuring tool',
      measureControlClasses: ['material-icons', 'smaller-icon'],
      measureControlLabel: 'straighten',
      tempLine: {
        color: 'orange',
        weight: 2
      },
      fixedLine: {
        color: 'orange',
        weight: 2
      },
      startCircle: {
        fillColor: 'orange',
        color: 'black',
        radius: 4,
        fillOpacity: 1
      },
      intermedCircle: {
        fillColor: 'orange',
        color: 'black',
        radius: 4,
        fillOpacity: 1
      },
      currentCircle: {
        fillColor: 'orange',
        color: 'black',
        radius: 5,
        fillOpacity: 1
      },
      endCircle: {
        fillColor: 'orange',
        color: 'black',
        radius: 4,
        fillOpacity: 1
      }
    })
  },
  disabled: {
    type: Boolean,
    default: false
  },
  showBrowserAlert: {
    type: Boolean,
    default: true
  },
  defaultMode: {
    type: String,
    default: ESiteMode.Operational
  },
  suppressModeWarning: {
    type: Boolean,
    default: false
  }
}

//for screenshot
let pluginOptions: PluginOptions = {
  cropImageByInnerWH: true, // crop blank opacity from image borders
  hidden: false, // hide screen icon
  preventDownload: false, // prevent download on button click
  domtoimageOptions: {}, // see options for dom-to-image
  position: 'topleft', // position of take screen icon
  screenName: 'screen', // string or function
  hideElementsWithSelectors: ['.leaflet-control-container'], // by default hide map controls All els must be child of _map._container
  mimeType: 'image/png', // used if format == image,
  caption: null, // string or function, added caption to bottom of screen
  captionFontSize: 15,
  captionFont: 'Arial',
  captionColor: 'black',
  captionBgColor: 'white',
  captionOffset: 5
}

export default {
  name: 'BaseMap',
  props,
  components: {
    SimpleLTileLayer,
    LMarker,
    EditableMap,
    LControlScale,
    LControl,
    LTooltip,
    LPopup,
    LLayerGroup,
    LControlPolylineMeasure,
    BrowserAlert,
    LeafletScreenshotTool
  },
  data: (): IData => ({
    baseUrl: process.env.BASE_URL,
    attribution: 'DroneShield',
    search: '',
    loading: false,
    fab: null,
    mapCenter: null,
    flyToMode: false,
    flyToMarker: [],
    mouseLocation: null,
    controlKey: 0
  }),
  computed: {
    ...mapState('sites', ['activeSiteId']),
    ...mapState('system', ['apiUrl']),
    ...mapState('sentries', ['sentriesSet']),
    ...mapState(['offline', 'sites']),
    ...mapState('drone_mcu_units', ['drone_mcu_unit']),
    ...mapState('maps', [
      'activeMapLayers',
      'mapLayerID',
      'allMapLayers',
      'siteMapLayerMapping'
    ]),
    ...mapGetters('maps', [
      'siteMapLayers',
      'activeSiteMapLayer',
      'fallbackMapLayer',
      'getMapLayerKey',
      'activeMapLayer',
      'activeMapLayerName',
      'activeMapLayerKey'
    ]),
    ...mapState('users', ['user']),
    ...mapGetters('system', ['systemSetting']),
    mgrsEnabled() {
      return this.user.settings.mgrsEnabled
    },
    maxLayerZoom() {
      return this.activeSiteMapLayer?.max_zoom || 18
    },
    activeMapLayerUrl() {
      return this.activeMapLayer?.url + this.getMapLayerKey
    },
    unit() {
      return this.user.settings.displayUnit
    },
    screenshotToolOptions() {
      return pluginOptions
    },
    scaleUnitsMetric() {
      return this.unit === 'metric'
    },
    mousePosition() {
      if (this.mouseLocation == null) return 'Unavailable'
      if (this.mgrsEnabled) {
        return latLngToMgrs(
          this.mouseLocation.lat,
          this.mouseLocation.lng,
          true
        )
      } else {
        return `${this.mouseLocation.lat}, ${this.mouseLocation.lng}`
      }
    },
    activeMapLayerHash(): string {
      if (this.activeMapLayer == null) return ''
      const hash = JSON.stringify([
        this.activeMapLayer.attribution,
        this.activeMapLayer.url
      ])
      return btoa(hash)
    },
    mapLayer(): string {
      return this.offline
        ? ['ESRI World Imagery Satellite', 'ESRI World Imagery Topology'][
            this.mapLayerID % 2
          ]
        : this.activeMapLayers && this.activeMapLayers.length
        ? this.activeMapLayers[this.mapLayerID % this.activeMapLayers.length]
        : 'ESRI World Imagery Satellite'
    },
    polylineMeaureOptions(): Object {
      let tempOptions = this.polylineOptions
      tempOptions.unit = this.unit
      return tempOptions
    },
    testSentries(): Array<ISentry> {
      return this.sentriesSet
    },
    isReady(): boolean {
      return this.center.length !== 0
    },
    offlineFolder(): string {
      return ['satellite', 'topology'][this.mapLayerID % 2]
    },
    drone_mcu_unit_state(): string {
      return this.drone_mcu_unit?.state
    },
    offline: {
      get(): boolean {
        return this.$store.state.offline
      },
      set(v): void {
        return this.$store.commit('setOffline', v)
      }
    },
    mapObject(): EditableMap {
      return (
        this.$refs[`editableMap-${this.name}`] &&
        this.$refs[`editableMap-${this.name}`].mapObject
      )
    }
  },
  mounted(): void {
    this.$emitter.on('invalidateMap', this.invalidateMap)
    this.$emitter.on('refresh-map-layer', this.refreshMapLayer)
    this.$emitter.on('mgrsUpdated', this.updateControls)

    this.$nextTick(async () => {
      await this.initializeMapLayer()
      if (!this.$refs.polyline) return
      const polylineObj = this.$refs.polyline.mapObject
      const oldStartLine = polylineObj._startLine
      const clearAll = polylineObj._clearAllMeasurements
      // @ts-ignore
      polylineObj._drawArrow = () => L.marker()
      polylineObj._startLine = function() {
        let nbPoints = 0
        clearAll.apply(this, arguments)
        oldStartLine.apply(this, arguments)
        const oldAddPoint = polylineObj._currentLine.addPoint
        polylineObj._currentLine.addPoint = function() {
          oldAddPoint.apply(this, arguments)
          if (++nbPoints === 2) polylineObj._finishPolylinePath()
        }
      }
    })
    this.$emitter.on('searchMap', ({ name, address }) => {
      if (this.name !== name) return
      this.searchLocation({ address })
    })
  },
  methods: {
    ...mapActions('drone_mcu_units', {
      sendCommand: 'SEND_DRONE_MCU_UNITS_COMMAND'
    }),
    ...mapActions('users', ['UPDATE_USER_SETTINGS']),
    ...mapActions('maps', [
      'incMapLayerID',
      'setMapLayer',
      'switchActiveMapLayer',
      'setActiveMapLayer'
    ]),
    invalidateMap() {
      this.$refs[`editableMap-${this.name}`].mapObject.invalidateMap()
    },
    updateControls() {
      this.controlKey++
    },
    onMouseMove(event) {
      this.mouseLocation = {
        lat: event.latlng.lat.toFixed(8),
        lng: event.latlng.lng.toFixed(8)
      }
    },
    mapLayerFabClicked(): void {
      this.switchMapLayer()
    },
    async initializeMapLayer(): Promise<void> {
      // await this.setActiveMapLayer(mapLayerId)
      // await this.updateUserProfileLatestMap(mapLayerId)
      // this.setMapLayer(this.user.settings.map)
    },
    canFlyTo(): boolean {
      return (
        this.drone_mcu_unit_state === 'in_air' ||
        this.drone_mcu_unit_state === 'on_ground' ||
        this.drone_mcu_unit_state === 'moving'
      )
    },
    goToMarkerCommand(position, settings): Promise<void> {
      // current in air altitude
      // @ts-ignore
      const current_altitude = Object.values(this.sentriesSet)[0].altitude
      //Altitude from pre flight settings
      const preFlightAltitude = settings.maxAltitude
      const calculateAltitude = () => {
        if (this.drone_mcu_unit_state === 'in_air') {
          return current_altitude.toString()
        }
        if (this.drone_mcu_unit_state === 'on_ground') {
          return preFlightAltitude.toString()
        }
      }
      return this.sendCommand({
        device_id: this.drone_mcu_unit.id,
        command: 'goto',
        parameters: [
          {
            key: 'Type',
            value: 'Drone'
          },
          {
            key: 'lat',
            value: position.lat.toString()
          },
          {
            key: 'long',
            value: position.lng.toString()
          },
          {
            key: 'alt',
            value: calculateAltitude()
          }
        ]
      })
    },
    async handleMapClick(e): Promise<void> {
      //if flyToMode is true then drop a marker
      if (this.flyToMode) {
        this.dropFlyToMarker(e.latlng)
        this.flyToMode = false
        await this.goToMarkerCommand(e.latlng, this.drone_mcu_unit.settings)
      }
      // do normal functionality
      this.$emit('click', e.latlng, e)
    },

    async dropFlyToMarker(position): Promise<IFlyToMarker> {
      return (this.flyToMarker[0] = {
        id: 'flyHere',
        position: { lat: position.lat, lng: position.lng },
        tooltip: `Going to \n Lat:${position.lat}\nLong:${position.lng}`,
        visible: true
      })
    },
    toggleFlyToMode(e): void {
      this.flyToMode = !this.flyToMode
      //stop events firing to the map behind button causing marker to be placed when the button is pressed.
      e.stopPropagation()
    },
    updateZoom(zoom): void {
      this.$emit('update:zoom', zoom)
    },
    updateCenter({ lat, lng }): void {
      this.mapCenter = [lat, lng]
      this.$emit('update:center', {
        lat: parseFloat(lat.toFixed(8)),
        lng: parseFloat(lng.toFixed(8))
      })
    },
    async searchLocation(params): Promise<void> {
      const { address } = params || {}
      this.loading = true
      const result = await this.$store.dispatch(
        'maps/SEARCH_MAP',
        address || this.search
      )
      const bbSize = distance(result.bb[0], result.bb[1])
      /* We check bounding box size, if its too large we dont call fitBoundsMap as it fails for certain countries and big areas */
      if (result && bbSize < 5000) {
        this.centerMap(result.loc, 3.5)
        this.fitBoundsMap(result.bb)
      } else {
        this.centerMap(result.loc, 5)
      }
      this.loading = this.fab = false
    },

    centerSentriesTimeout(timeout = 3000): void {
      if (!this.getAnchoredSentries().length) return
      setTimeout(() => {
        this.centerAnchoredSentries()
      }, timeout)
    },
    //main function for centering locked sentries
    centerAnchoredSentries(): void {
      if (!this.anchorable) return //makes sure only enable if prop is passed
      const siteId = this.sites.activeSiteId
      const anchoredSentries = this.getAnchoredSentries()
      //if there are anchored sentries, we move the bounding box
      if (anchoredSentries.length) {
        const bounds = anchoredSentries.map(i => [i.latitude, i.longitude])
        this.fitBoundsMap(bounds, 20, true, this.zoom)
      } else {
        //otherwise center on site
        this.centerMap(this.center, this.zoom)
      }
    },
    //returns array of anchored snetires for this site.
    getAnchoredSentries(): Array<ISentry> {
      const anchoredSentries = []
      let sentries = Object.keys(this.sentriesSet).map(key => {
        if (this.sentriesSet[key] && this.sentriesSet[key].anchored === true) {
          anchoredSentries.push(this.sentriesSet[key])
        }
      })
      return anchoredSentries
    },
    centerMap(center, zoom, animate = true): void {
      if (!center || center[0] === undefined || center[1] === undefined) return
      if (this.mapObject) {
        this.mapObject.flyTo(center, zoom, {
          animate
        })
      }
    },
    invalidateSize(): void {
      if (this.mapObject) {
        this.mapObject.invalidateSize()
      }
    },
    fitBoundsMap(bounds, padding, animate = true, zoom): void {
      if (bounds && this.mapObject) {
        this.mapObject.flyToBounds(bounds, {
          padding: [padding || 0, padding || 0],
          animate,
          maxZoom: zoom || 15
        })
      }
    },
    //returns data URI image of current map
    //might need to setTimeout 500 when calling this as it will not be initalised
    async screenshotMap() {
      let format = 'image' // 'image' - return base64, 'canvas' - return canvas
      return this.simpleMapScreenshoter.takeScreen(format)
    },
    dragEventsHandler(): void {
      let dragging = false
      this.mapObject.on('dragstart', () => {
        dragging = true
      })

      this.mapObject.on(
        'dragend',
        debounce(() => {
          if (!dragging) {
            this.centerSentriesTimeout()
          }
        }, 1500)
      )

      this.mapObject.on('dragend', () => {
        dragging = false
      })
    },
    mapReady(): Promise<void> | void {
      this.$nextTick(() => {
        if (this.mapObject) {
          this.mapObject.createPane('fixed', this.mapObject._container)
          //anchoring logic
          this.centerAnchoredSentries() //this makes sure that the map is anchored between page loads inside the app

          this.dragEventsHandler()
          // Emit current zoom on mapReady for offline maps
          this.$emit('update:zoom', this.mapObject._zoom)
        }

        this.$bus.$on('screenshotMap', async name => {
          try {
            if (this.name !== name) return

            const img = await this.$refs['screenshot-tool'].takeScreenShot()
            this.$store.commit('setMapScreenshot', img)
            return img
          } catch (err) {
            throw err
          }
        })
        this.$bus.$on(
          'mapCenter',
          (name, coordinates, zoom, animate = true) => {
            if (!coordinates || this.name !== name) return
            this.centerMap(coordinates, zoom, animate)
          }
        )
        this.$bus.$on(
          'mapFitBounds',
          (name, bounds, padding, animate = true) => {
            if (!bounds || this.name !== name) return
            this.fitBoundsMap(bounds, padding, animate)
            if (!animate) this.invalidateSize()
          }
        )
        this.$bus.$on('mapCenterAnchoredCentries', this.centerAnchoredSentries)

        setTimeout(() => {
          //fixes map size not adjusting based on different pages
          //setTimeout is needed, not sure why.
          this.invalidateSize()
        }, 600)
      })
    },
    async switchMapLayer(): Promise<void> {
      await this.switchActiveMapLayer()
      await this.updateUserProfileLatestMap(this.activeMapLayerKey)
    },
    async updateUserProfileLatestMap(mapKey: string): Promise<void> {
      const newUser = Object.assign({}, this.user)
      newUser.settings.mapLayerKey = mapKey
      await this.UPDATE_USER_SETTINGS(newUser)
    },
    async refreshMapLayer() {
      await this.initializeMapLayer()
    }
  },
  beforeDestroy(): void {
    this.$bus.$off('mapExport')
    this.$bus.$off('screenshotMap')
    this.$bus.$off('mapFitBounds')
    this.$bus.$off('mapCenterAnchoredCentries')
    this.$bus.$off('mapCenter')
    this.$emitter.off('searchMap')
    this.$emitter.off('refresh-map-layer', this.refreshMapLayer)
    this.$emitter.off('invalidateMap', this.invalidateMap)
  },
  watch: {
    scaleUnitsMetric: {
      handler: function() {
        this.controlKey++
      }
    },
    //this makes sure that the map is anchored on initial page load
    sentriesSet: {
      handler: function(n, o): void {
        this.centerAnchoredSentries()
      },
      immediate: false
    },
    fab(v): void {
      this.search = ''
      if (v) {
        this.$nextTick(() => {
          this.$refs.inputSearch.focus()
        })
      }
    },
    disabled(v): void {
      if (!this.mapObject) return
      this.mapObject._handlers.forEach(function(handler) {
        v ? handler.disable() : handler.enable()
      })
    },
    mapLayer: {
      handler: async function(payload): Promise<void> {
        try {
          if (payload) {
            await this.updateUserProfileLatestMap(payload)
          }
        } catch (err) {}
      }
    }
  }
}
