export interface ISite {
  id?: number
  name: string
  client_id: number
  latitude: number
  longitude: number
  map_center_latitude: number
  map_center_longitude: number
  accept_location_updates: boolean
  alert_suppression_time_window: number
  auto_jam: boolean
  auto_jam_interval: number
  auto_disruption_probability: number
  auto_disruption_detection_count: number
  show_whitelisted_detections: boolean
  system_warning_enabled: boolean
  timezone: string
  trigger_sensors_offline_alerts: boolean
  zone_trigger_probability: number
  zone_trigger_detection_count: number
  zoom_level: number
  locator_id: number
  created_at?: string
  updated_at?: string
  heartbeat_at?: string
  last_notification_sent_at?: string
  mode: ESiteMode
  minimum_display_probability: number
  speedleader_estimation_time: number
  always_show_rf_beam: boolean
  sensitivity: ESensitivity
  max_location_variance: number
  round_frequency_band: ERoundFrequencyBand
  prob_uav_filter: boolean
  birth_model_height_min: number
  birth_model_height_max: number
  fuse_controller_detections: boolean
  drone_locator_detection_timeout: number
}

export enum ESiteMode {
  Operational = 'operational',
  Monitor = 'monitor',
  Calibration = 'calibration'
}

export enum ESensitivity {
  Normal = 'normal',
  High = 'high'
}

export enum ERoundFrequencyBand {
  None = 'none',
  Controller = 'controller',
  All = 'all'
}

export interface ISiteRequest {
  site: ISite
}

export interface ISiteResponse {
  site: ISite
  locator_id: number
}

export interface ISiteListResponse {
  sites: ISite[]
}

export interface ISitesSet {
  [key: number]: ISite
}

export interface ISiteOrigin {
  latitude: number
  longitude: number
  timezone: string
  zoom_level: number
}

export interface ISiteState {
  sitesSet: ISitesSet
  activeSiteId: string
  status: string
  error: string
  simulationRunning: boolean
  simulationStartLoading: boolean
  newSiteOrigin: ISiteOrigin
}

export interface IData {
  valid: boolean
  name: string
  defaultSite: boolean
  siteDataClone: ISite
}

export interface IDataEdit {
  defaultSite: boolean
  valid: boolean
  name: string
  deleteDialog: boolean
  selectedTheme: string
  siteData: ISite
  siteResetData: ISite
}

export interface ISchemaObj {
  label: string
  required?: boolean
  key: string
  fieldType?: string
  value?: number | string | boolean
}

export const AUTO_JAM_AND_ZONE_TRIGGER_PROBABILITIES = [
  { label: 'All (0%)', value: 0.0 },
  { label: 'Low Confidence (20%)', value: 0.2 },
  { label: 'Low-Medium Confidence (40%)', value: 0.4 },
  { label: 'Medium Confidence (60%)', value: 0.6 },
  { label: 'High Confidence (80%)', value: 0.8 }
]

export const MIN_BIRTH_MODEL_HEIGHT = -500
export const MAX_BIRTH_MODEL_HEIGHT = 500

export interface IPartialSite {
  id: number
  auto_jam?: boolean
  mode?: ESiteMode
}
