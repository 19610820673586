<template>
  <l-feature-group :visible="visible" @ready="ready">
    <!-- detection sector -->
    <l-circle
      v-if="!showJammingSectorOnly && !jamming"
      :visible="visible"
      :radius="sensor.reach"
      :color="color"
      :weight="weight"
      :fill-color="fillColor"
      :opacity="1"
      :fill-opacity="0.1"
      :lat-lng="latlng"
      :ref="`${refKey}-circle`"
      :className="draggable ? 'wizardSector' : ''"
    />
    <!-- sector for omni jamming -->
    <l-circle
      v-if="isDsxModelJamming(sensor.model) && !hideCannon"
      :visible="visible"
      :radius="sensor.reach_jamming"
      :color="jammerColor"
      :weight="weight"
      :fill-color="jammerFillColor"
      :opacity="1"
      :fill-opacity="fillOpacity"
      :lat-lng="latlng"
      :ref="`${refKey}-circle-jamming`"
      :className="draggable ? 'wizardSector' : ''"
    />

    <template v-if="editMode">
      <!-- White circle -->
      <circle-fog-of-war
        :radius="sensor.reach"
        fill-color="black"
        :opacity="0"
        :fill-opacity="0"
        :lat-lng="latlng"
        mask="rfSector"
      />
      <!-- Black background -->
      <polygon-fog-of-war
        :fill-opacity="0"
        :mask-opacity="0.7"
        fill-color="black"
        :opacity="0"
        mask-id="rfSector"
      />
    </template>
  </l-feature-group>
</template>
<script>
import utils from '../utils'
import { icon } from 'leaflet'
import { LCircle, LFeatureGroup, LPolygon } from 'vue2-leaflet'
import { PolygonFogOfWar, CircleFogOfWar } from 'vue2-leaflet-fogofwar'
import {
  isDsxModelJamming,
  isDsxModelDirectional
} from '@/store/modules/rf_sensors/types'

const props = {
  active: {
    type: Boolean,
    default: false
  },
  jamming: {
    type: Boolean,
    default: false
  },
  visible: {
    type: Boolean,
    default: true
  },
  editMode: {
    type: Boolean,
    default: false
  },
  offset: {
    type: Number,
    default: 0
  },
  draggable: {
    type: Boolean,
    default: false
  },
  hideCannon: {
    type: Boolean,
    default: false
  },
  showJammingSectorOnly: {
    type: Boolean,
    default: false
  },
  sensor: {
    type: Object,
    default: () => ({
      reach: 0,
      direction: 0,
      angle: 105,
      latitude: 0,
      longitude: 0
    })
  }
}

const SECTOR_MID_OFFSET = 22.5

export default {
  name: 'DronesentryxSectorCoverage',
  props,
  components: {
    LCircle,
    LFeatureGroup,
    PolygonFogOfWar,
    CircleFogOfWar
  },
  data: () => ({
    compassIcon: icon({
      iconUrl: require(`@/assets/compass.png`),
      iconSize: [400, 400],
      iconAnchor: [200, 200]
    }),
    fillOpacity: 0.1,
    prev: 0,
    isDsxModelJamming,
    isDsxModelDirectional
  }),
  computed: {
    weight() {
      return this.active ? 3 : 1
    },
    fillColor() {
      return this.active ? 'orange' : '#0080F7'
      // return "white";
    },
    jammerFillColor() {
      if (this.jamming || this.showJammingSectorOnly) {
        return 'red'
      } else if (this.active) {
        return 'orange'
      } else {
        return '#FF7E27'
      }
    },
    compassLatlng() {
      const { sensor } = this
      return [sensor.latitude || 0, sensor.longitude || 0]
    },
    compassDirection() {
      return this.sensor.direction
    },
    jammerColor() {
      return this.jammerFillColor
    },
    color() {
      return this.active ? 'orange' : '#0080F7'
    },

    type() {
      return this.sensor.model
    },
    latlng() {
      return this.sensor && [this.sensor.latitude, this.sensor.longitude]
    },
    refKey() {
      return `dronesentryx-sector-${this.sensor.id}`
    }
  },
  methods: {
    ready(obj) {
      obj.bringToBack()
    },
    /* Gets the 4 sector ranges, direction based on orientation */
    getSectorRanges() {
      const angle = 45 // each sector will always be 45 deg
      let { reach, direction = 0 } = this.sensor || {}
      direction = Math.round(direction) - SECTOR_MID_OFFSET //offset to make the front sector aligned in the middle given 8 sectors
      const ranges = []
      let nextDirection = direction //set the initial direction
      for (let i = 0; i < 8; i++) {
        ranges.push(this.directionalSectorRange(reach, nextDirection, angle))
        nextDirection = nextDirection + angle
      }
      return ranges
    },
    glow() {
      if (
        this.fillOpacity <= 0 ||
        (this.prev - this.fillOpacity < 0 && this.fillOpacity < 0.4)
      ) {
        this.prev = this.fillOpacity
        this.fillOpacity += 0.01
      } else {
        this.prev = this.fillOpacity
        this.fillOpacity = this.fillOpacity - 0.01
      }
    },
    directionalSectorRange(reach, direction, angle) {
      return reach
        ? utils.createRange({
            reach,
            direction: direction + this.offset,
            angle,
            vertex: [this.sensor.latitude, this.sensor.longitude]
          })
        : []
    }
  },
  timers: {
    glow: { time: 30, autostart: false, repeat: true }
  },
  watch: {
    jamming(value) {
      if (value) {
        this.$timer.start('glow')
      } else {
        this.$timer.stop('glow')
        this.fillOpacity = 0.2
        this.prev = 0
      }
    },
    active(isActive) {
      isActive && utils.bringSVGToTop(this.$refs[this.refKey])
    }
  },
  mounted() {
    if (this.jamming) {
      this.$timer.start('glow')
    }
  }
}
</script>
<style scoped>
.move-back {
  z-index: -999;
}
</style>
