<template>
  <l-marker
    :ref="`sentry_${sentry.id}`"
    :id="`sentrymarker-${sentry.id}`"
    :draggable="draggable"
    :icon="sentryIconType === 'dro' ? sentrySymbol : null"
    @click="onSelectSentry"
    @moveend="$emit('moveend', $event)"
    @move="$emit('move', $event)"
    :lat-lng="latlng"
    @update:latLng="$emit('update:latLng', $event)"
    :zIndexOffset="5"
  >
    <l-icon
      v-if="sentryIconType === '2525'"
      :iconSize="[iconDimensions.width, iconDimensions.height]"
      :iconAnchor="[iconDimensions.width / 2, iconDimensions.height / 2]"
    >
      <MilStd2525SentryIcon
        class="mil-std-icon"
        :id="sentry.id"
        :sensors="filteredSensors"
        @updated:dimensions="iconDimensions = $event"
      />
    </l-icon>
    <l-popup
      :ref="`pop_${sentry.id}`"
      :options="options"
      @remove="removePopup($event)"
    >
      <v-container class="pa-0 pt-2">
        <v-layout row>
          <v-flex :shrink="!isPlanningTool" class="pa-0">
            <span>
              <b style="margin-right: 0">{{ this.sentry.name }}</b>
            </span>
            <v-tooltip top>
              <v-btn
                slot="activator"
                disabled
                class=""
                icon
                dark
                style="margin: 0"
              >
                <v-icon small :color="this.sentry.anchored ? 'orange' : ''">{{
                  this.sentry.anchored ? 'lock' : ''
                }}</v-icon>
              </v-btn>
              <span>This installation is locked/centered </span>
            </v-tooltip>
          </v-flex>

          <!-- Camera section -->
          <template
            v-if="
              !isPlanningTool &&
                cameras.length > 0 &&
                !calibratingCamera
            "
          >
            <v-flex shrink class="pa-0">
              <v-btn
                flat
                icon
                small
                @click="setActiveCamera(liveCamera ? null : cameras[0])"
                :color="liveCamera ? 'white' : 'grey'"
                class="mt-1 ml-1"
              >
                <v-icon :class="{ on: liveCamera != null }">camera</v-icon>
              </v-btn>
            </v-flex>
            <v-flex grow class="pa-0">
              <v-select
                single-line
                hide-details
                class="ml-2 pt-0 mt-0"
                style="display: inline-block"
                v-if="liveCamera"
                :items="cameras"
                item-text="name"
                item-value="id"
                label="Optical sensors"
                v-model="liveCamera"
                @mouseup="focusStream"
              ></v-select>
            </v-flex>
            <v-flex shrink class="pa-0">
              <v-btn
                flat
                icon
                small
                v-if="liveCamera && $route.name !== 'installations'"
                @click="detachCurrentSentryPopup"
                :color="detached ? 'white' : 'grey'"
                class="mt-1 mx-5"
              >
                <v-icon>branding_watermark</v-icon>
              </v-btn>
            </v-flex>
          </template>
        </v-layout>
        <!-- End camera section -->

        <!-- Stream video here -->
        <v-flex class="ma-0 mt-1">
          <template v-if="!isPlanningTool && cameras.length > 0">
            <stream
              ref="stream"
              v-if="liveCamera && activeCamera"
              :camera_id="activeCamera.id"
              :controls="[false, 'cal-1-1'].includes(calibratingCamera)"
              :calibrating="calibratingCamera"
              :camera_data="camerasSet[activeCamera.id]"
              :detections="activeCameraDetections"
              :switchableStream="environment === 'ZenithAerotech'"
              style="width: 700px"
              @ptz="CAMERA_CONTROL($event)"
            >
              <!-- Only show if Zenith Aerotech -->
              <template
                v-if="this.environment === 'ZenithAerotech'"
                v-slot:commandStatus
              >
                <CommandStatus />
              </template>

              <template
                v-if="this.environment === 'ZenithAerotech'"
                v-slot:flightControl
              >
                <div class="flight-control-container"></div>
              </template>

              <!-- END flight control -->
            </stream>
          </template>
          <template v-if="sentry.sensors">
            <template
              v-for="(type, i) in Object.keys(sensorsByType('rf_sensor'))"
            >
              <span :key="`RFSP-${i}`" class="dot rf-dot" />
              {{ sensorsByType('rf_sensor')[type].length
              }}{{
                (type === 'rf_zero'
                  ? 'xRfZero'
                  : type === 'rf_two'
                  ? 'xRfOne MkII'
                  : 'xRfOne') +
                  `: r=${convertValIfImperial(
                    systemSetting,
                    sensorsByType('rf_sensor')[type][0].reach / 1000,
                    'km'
                  ).toString()}${convertUnitIfImperial(systemSetting, 'km')}`
              }}
              <br :key="`RFBR-${i}`" />
            </template>
            <template
              v-for="(type, i) in Object.keys(sensorsByType('dsx_sensor'))"
            >
              <span :key="`DSXSP-${i}`" class="dot rf-dot" />
              {{ sensorsByType('dsx_sensor')[type].length }}{{ `x` }}
              {{
                `DSX: r=${convertValIfImperial(
                  systemSetting,
                  sensorsByType('dsx_sensor')[type][0].reach / 1000,
                  'km'
                ).toString()}${convertUnitIfImperial(systemSetting, 'km')}`
              }}
              <br :key="`DSXBR-${i}`" />
            </template>
            <template v-for="(type, i) in Object.keys(sensorsByType('radar'))">
              <span :key="`RADSP-${i}`" class="dot radar-dot" />
              {{ sensorsByType('radar')[type].length }}{{ radarLabels[type] }}
              <br :key="`RADBR-${i}`" />
            </template>
            <!-- For discovair -->
            <template
              v-for="(type, i) in Object.keys(
                sensorsByType('discovair_sensor')
              )"
            >
              <span :key="`DISCSP-${i}`" class="dot discovair-dot" />
              {{ sensorsByType('discovair_sensor')[type].length }}x Discovair:
              {{
                `r=${convertValIfImperial(
                  systemSetting,
                  0.5,
                  'km'
                )}${convertUnitIfImperial(systemSetting, 'km')}`
              }}
              <br :key="`DISCBR-${i}`" />
            </template>
            <template v-for="(reach, i) in Object.keys(cannonsByReach)">
              <span :key="`CANSP-${i}`" class="dot cannon-dot`" />
              {{ cannonsByReach[reach].length }}xDroneCannon: r={{
                convertValIfImperial(systemSetting, reach / 1000, 'km')
              }}{{ convertUnitIfImperial(systemSetting, 'km') }}
              <br :key="`CANBR-${i}`" />
            </template>
            <template v-for="(type, i) in Object.keys(sensorsByType('camera'))">
              <span
                :key="`CAMSP-${i}`"
                :class="`dot camera-dot${type === 'droneopt2' ? '-opt2' : ''}`"
              />
              {{ sensorsByType('camera')[type].length }}{{ cameraLabels[type] }}
              <br :key="`CAMBR-${i}`" />
            </template>
          </template>
        </v-flex>
      </v-container>
    </l-popup>
  </l-marker>
</template>

<script>
import { icon } from 'leaflet'
import utils from '@/utils/utils'
import { LMarker, LPopup, LIcon } from 'vue2-leaflet'
import { mapActions, mapGetters, mapState } from 'vuex'
import { getTheme } from '@/components/Map/utils/themes'
import CommandStatus from '@/components/Widgets/FlightControl/Components/Widgets/CommandStatus'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'
import MilStd2525SentryIcon from './SentryMarker/MilStd2525SentryIcon.vue'

const props = {
  draggable: {
    type: Boolean,
    default: false
  },
  sentry: {
    type: Object,
    default: () => ({
      id: null,
      latitude: 0,
      longitude: 0
    })
  },
  coords: {
    type: Array
  },
  activeSentryId: {
    type: Number,
    default: null
  },
  active: {
    type: Boolean,
    default: false
  },
  iconColor: {
    type: String,
    default: 'green'
  },
  iconSize: {
    type: Array,
    default: () => [50, 50]
  },
  shadow: {
    type: Boolean,
    default: false
  },
  radarLabel: {
    type: Object,
    default: () => ({
      radar_zero: 'xRadarZero: r=1km',
      radar_one: 'xRadarOne: r=1.5km',
      radar_two: 'xRadarTwo: r=3.5km'
    })
  },
  cameraLabel: {
    type: Object,
    default: () => ({
      droneopt1: 'xDroneOpt1: r=1km',
      droneopt2: 'xDroneOpt2: r=0.5-1km'
    })
  },
  sensors: {
    type: Object,
    default: () => ({})
  },
  cameraDetections: {
    type: Object
  }
}

export default {
  name: 'SentryMarker',
  props,
  data: () => ({
    detached: false,
    isDroneSentryType: true,
    iconDimensions: { width: 0, height: 0 }
  }),
  components: {
    MilStd2525SentryIcon,
    LIcon,
    LMarker,
    LPopup,
    CommandStatus
  },
  computed: {
    ...mapGetters('sentries', ['sentriesInSite']),
    ...mapState('selection', ['activeCamera']),
    ...mapState('system', ['environment']),
    ...mapState('sites', ['activeSiteId']),
    ...mapState('plans', ['isWizard', 'isDragging']),
    ...mapState('cameras', ['calibratingCamera', 'camerasSet']),
    ...mapState('users', ['isDeveloper', 'developerSettings']),
    ...mapGetters('system', ['systemSetting']),
    ...mapGetters('cameras', ['currentTrackedTargetId', 'currentCamera']),
    cannonsByReach() {
      return (
        (this.sentry.sensors &&
          this.sentry.sensors.cannons &&
          this.sentry.sensors.cannons.length &&
          utils.groupBy(this.sentry.sensors.cannons, 'reach')) ||
        []
      )
    },
    activeSentryInformation() {
      return this.sentriesInSite(this.activeSiteId).find(
        sentry => sentry.id === this.activeSentryId
      )
    },
    sentryType() {
      return this.sentry.sentry_type || 'fixed'
    },
    sentryLocation() {
      return {
        lat: this.sentry.latitude,
        lng: this.sentry.longitude,
        id: this.sentry.id,
        name: this.sentry.name,
        anchored: this.sentry.anchored
      }
    },
    sensorsByType() {
      return type => {
        let groupKey = type === 'rf_sensor' ? 'model' : `${type}_type`
        if (type === 'discovair_sensor') groupKey = 'type'
        return (
          (this.sentry.sensors &&
            this.sentry.sensors[`${type}s`] &&
            this.sentry.sensors[`${type}s`].length &&
            utils.groupBy(this.sentry.sensors[`${type}s`], groupKey)) ||
          []
        )
      }
    },
    latlng() {
      const { sentry, coords } = this
      return coords ? coords : [sentry.latitude, sentry.longitude]
    },
    img() {
      return `${this.iconColor}${this.isWizard ? '.large' : ''}.${
        this.active ? 'select.' : ''
      }svg`
    },
    theme() {
      return getTheme(this.$store.state.theme)
    },
    sentryIcon() {
      return icon({
        iconUrl: require(`@/assets/sentry/${this.sentryType}/${this.img}`), //TODO : add correct assets to each type
        iconSize: this.calibratingCamera
          ? [0, 0]
          : this.isWizard
          ? [58, 59.6]
          : this.iconSize,
        iconAnchor: [
          this.isWizard ? 28.6 : this.iconSize[0] / 2,
          this.isWizard ? 49.9 : this.iconSize[1]
        ],
        shadowUrl:
          this.shadow && !this.isWizard
            ? require(`@/assets/sentry/shadow.png`)
            : null,
        shadowSize: this.shadow ? this.iconSize : null,
        shadowAnchor: this.shadow
          ? [this.iconSize[0] / 2, this.iconSize[1]]
          : null,
        className: `sentry-marker sentry-id-${this.sentry.id}`
      })
    },
    options() {
      return Object.assign(
        {},
        {
          className: this.isPlanningTool
            ? 'proposal-popup'
            : 'sentry-popup dark',
          offset: this.isPlanningTool ? this.sentry.offset : [124, -43],
          autoClose: !this.isPlanningTool,
          closeOnClick: !this.isPlanningTool
        }
      )
    },
    liveCamera: {
      get: function() {
        return this.activeCamera?.id
      },
      set: function(v) {
        this.setActiveCamera(v ? this.camerasSet[v] : null)
      }
    },
    popupObject() {
      return this.$refs[`pop_${this.sentry.id}`].mapObject
    },
    markerObject() {
      return this.$refs[`sentry_${this.sentry.id}`].mapObject
    },
    isPlanningTool() {
      return this.$route.name === 'Plans'
    },
    filteredSensors() {
      return Object.entries(this.sensors).reduce(
        (acc, [sensorType, sensors]) => {
          acc[sensorType] = sensors.filter(s => s.sentry_id === this.sentry.id)
          return acc
        },
        {}
      )
    },
    cameras() {
      return this.filteredSensors?.cameras || []
    },
    sentryIconType() {
      return this.theme.icons.type.sentry
    },
    sentrySymbol() {
      return this.sentryIcon
    },
    activeCameraDetections() {
      return Object.fromEntries(
        Object.entries(this.cameraDetections || {}).filter(
          ([k, _]) => k.split(':')[0] == this.activeCamera?.id
        )
      )
    },
    radarLabels() {
      if (this.systemSetting.displayUnit === 'imperial') {
        return {
          radar_zero: 'xRadarZero: r=0.62mi',
          radar_one: 'xRadarOne: r=0.93mi',
          radar_two: 'xRadarTwo: r=2.17mi'
        }
      }
      return this.radarLabel
    },
    cameraLabels() {
      if (this.systemSetting.displayUnit === 'imperial') {
        return {
          droneopt1: 'xDroneOpt1: r=0.62mi',
          droneopt2: 'xDroneOpt2: r=0.31-0.62mi'
        }
      }
      return this.cameraLabel
    }
  },
  methods: {
    ...mapActions('cameras', ['CAMERA_CONTROL']),
    ...mapActions('selection', [
      'activateSentry',
      'activateGroup',
      'activateSensor',
      'setActiveCamera',
      'deactivateSensor'
    ]),

    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,

    async onSelectSentry() {
      await this.activateSentry(this.sentry.id)
      this.$emit('selectSentry', this.sentry.id)
    },
    focusStream() {
      this.$refs.stream.$el.focus()
    },
    async openPopupSentry() {
      if (!this.sentry?.id) return
      const popup = this.$refs?.[`sentry_${this.sentry.id}`]?.mapObject
      if (popup) {
        if (!popup.isPopupOpen()) popup.openPopup()
        await this.$nextTick()
        if (this.liveCamera && !this.isPlanningTool && !this.detached)
          this.detachCurrentSentryPopup()
      }
      this.popupObject._container
        .querySelector('.leaflet-popup-close-button')
        .addEventListener('click', () => this.setActiveCamera(null))
    },
    detachPopup(popup) {
      if (!popup || !this.popupObject) {
        return console.warn(
          'Popup object does not exist, it could not be detached'
        )
      }
      this.detached = !this.detached
      const popupObject = this.popupObject
      if (this.detached) {
        popup.className += ' leaflet-fixed-pane'
        popupObject._map.closePopup = function(popup) {
          if (!popup || popup === this._popup) {
            popup = this._popup
            this._popup = null
          }
          if (popup && popup._leaflet_id !== popupObject._leaflet_id) {
            this.removeLayer(popup)
          }
          return this
        }
        popupObject._closeButton.onclick = () => {
          if (popupObject._map) popupObject._map.removeLayer(popupObject)
        }
      } else {
        popup.className = popup.className?.replace(' leaflet-fixed-pane', '')
        popupObject._map.closePopup = function(popup) {
          if (!popup || popup === this._popup) {
            popup = this._popup
            this._popup = null
          }
          if (popup) {
            this.removeLayer(popup)
          }
          return this
        }
        popupObject._closeButton.onclick = null
      }
      const pane = document.querySelector(
        this.detached
          ? '.map div.leaflet-pane.leaflet-fixed-pane'
          : '.map div.leaflet-pane.leaflet-popup-pane'
      )
      if (pane) pane.appendChild(popup)
    },
    removePopup(e) {
      if (this.activeCamera?.sentry_id === this.sentry.id && this.detached)
        this.detachPopup(e.target._container)
    },
    closeAllPopups() {
      this.$nextTick(() => {
        if (this.popupObject._map) this.popupObject._map.closePopup()
      })
    },
    detachCurrentSentryPopup() {
      if (this.$route.name === 'installations') return
      this.detachPopup(this.$refs[`pop_${this.sentry.id}`].$el.offsetParent)
    },
    activateAndOpenPopup() {
      if (this.activeCamera?.sentry_id === this.sentry.id) {
        this.activateSensor({
          sensor: this.activeCamera.id,
          sentry: this.sentry.id,
          type: 'cameras'
        })
        this.openPopupSentry()
      }
    }
  },
  async mounted() {
    this.$emitter.on('openPopup', sentryId => {
      if (this.sentry.id === sentryId) this.openPopupSentry()
    })
    this.$bus.$on('updatePopup', sentryId => {
      if (sentryId === this.sentry.id) {
        this.popupObject.update()
      }
    })
    this.markerObject._icon.addEventListener('dragover', () =>
      this.markerObject._icon.classList.add('mdrop')
    )
    this.markerObject._icon.addEventListener('dragleave', () =>
      this.markerObject._icon.classList.remove('mdrop')
    )
    if (this.activeCamera) {
      await this.$nextTick()
      this.activateAndOpenPopup()
    }
  },
  beforeDestroy() {
    if (this.detached && this.popupObject._map) this.popupObject._map.removeLayer(this.popupObject)
    this.$emitter.off('openPopup')
    this.$bus.$off('updatePopup')
  },
  watch: {
    activeCamera(camera) {
      if (this.isPlanningTool) return
      if (camera) {
        this.activateAndOpenPopup()
      } else {
        if (this.detached) this.detachCurrentSentryPopup()
        this.cameras.forEach(camera =>
          this.deactivateSensor({
            sensor: camera.id,
            sentry: camera.sentry_id,
            type: 'cameras'
          })
        )
      }
    },
    sentryLocation: {
      handler: function(n, o) {
        const { lat, lng, id, name, anchored } = n
        //if anchored fire map bounds event from basemap
        if (anchored === true) {
          this.$bus.$emit('mapCenterAnchoredCentries')
        }
      }
    },
    isDragging(v) {
      if (v) {
        this.markerObject._icon.classList.add('mdrag')
      } else {
        this.markerObject._icon.classList.remove('mdrag')
      }
    },
    activeSentryId(id) {
      if (this.sentry.id === id) {
        if (this.isPlanningTool) this.openPopupSentry()
        if (this.detached) this.closeAllPopups()
        this?.activeSentryInformation?.sentry_type === 'drone'
          ? (this.isDroneSentryType = true)
          : (this.isDroneSentryType = false)
      } else if (!id) {
        this.$store.commit('setActiveGroup', {})
      }
    },
    currentTrackedTargetId() {
      if (this.currentTrackedTargetId && !this.liveCamera) {
        if (this.isDeveloper && this.developerSettings.doNotOpenCameraPopup)
          return
        this.setActiveCamera(this.currentCamera)
      }
    }
  }
}
</script>

<style scoped>
.flight-control-container {
  width: 100%;
  position: absolute;
  display: flex;
}
v-icon {
  transition: transform 0.3s ease;
}
.on {
  transform: rotate(180deg);
}
</style>

<style>
.leaflet-popup .v-input__slot:before,
.leaflet-popup .v-input__slot:after {
  border: none !important;
}
.leaflet-fixed-pane {
  position: absolute;
  z-index: 9999 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: auto !important;
  transform: none !important;
  margin: 0;
  border-radius: 0;
  margin-bottom: 0 !important;
}
.sentry-marker.mdrop {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg"%3E%3Cg opacity="0.2"%3E%3Crect x="7" y="37" width="7" height="22" fill="rgb(0, 0, 0)"/%3E%3Crect y="44.5" width="22" height="7" fill="rgb(0, 0, 0)"/%3E%3C/g%3E%3Crect x="8" y="38" width="5" height="20" fill="rgb(0, 166, 81)"/%3E%3Crect x="1" y="45.5" width="20" height="5" fill="rgb(0, 166, 81)"/%3E%3C/svg%3E'),
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle r="29" cx="29" cy="29" fill="rgba(50,50,50,0.5)"/%3E%3C/svg%3E') !important;
  background-size: 100% 100%;
}
.sentry-marker.mdrag {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle r="29" cx="29" cy="29" fill="rgba(50,50,50,0.5)"/%3E%3C/svg%3E');
  background-size: 100% 100%;
}
.sentry-popup .leaflet-popup-content-wrapper {
  min-width: 300px;
  background: rgba(48, 48, 48, 0.8);
  color: #fff;
  border-left: 4px solid orange;
  border-radius: 0;
}
.sentry-popup .leaflet-popup-content {
  margin: 3px 0 0 0;
  width: auto !important;
}
.sentry-popup b {
  padding-left: 1.5em;
  margin: 10px 10px 10px 0px;
  display: inline-block;
}
.sentry-popup .leaflet-popup-tip {
  background: transparent;
  border: none;
  box-shadow: none;
}
</style>
